/*
 * @Author: your name
 * @Date: 2020-12-01 11:10:17
 * @LastEditTime: 2023-11-07 13:52:30
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: In User Settings Edit
 * @FilePath: \slide-tct\src\main.js
 */
import "babel-polyfill"
import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './vuex/store';
import ElementUI from 'element-ui'
import {
  Message
} from 'element-ui';

import {
  Toast
} from 'vant';
import {
  Dialog
} from 'vant';

//引用md5插件
import md5 from 'js-md5'

import 'fullpage.js/vendors/scrolloverflow';
import VueFullPage from 'vue-fullpage.js';
import 'fullpage.js/dist/fullpage.min.css';

import './utils/rem'

import animated from 'animate.css' // npm install animate.css --save安装，在引入

import Vant from 'vant';

import Carousel3d from 'vue-carousel-3d';

import wow from '../node_modules/wowjs/dist/wow'


// 引入长按事件
import './utils/derective.js'

import './styles/reset.css'
import './styles/font/iconfont.css'
import '../theme/index.css'
// import JsonExcel from 'vue-json-excel'  // 导出为excel插件
import 'vant/lib/index.css';

import "vue-scroll-picker/dist/style.css"


// Vue.use(Jquery_contextmenu)

Vue.use(ElementUI)
Vue.use(Vant)


Vue.use(VueFullPage);

Vue.use(Carousel3d);

Vue.use(animated);

Vue.prototype.$message = Message;
Vue.prototype.$toast = Toast
Vue.prototype.$dialog = Dialog

//将md5绑定到全局
Vue.prototype.$md5 = md5
Vue.prototype.$wow = wow

//路由跳转前的判断
// router.beforeEach((to, from, next) => {
//   let userInfo = sessionStorage.getItem('userInfo');
//   let access_token = JSON.parse(userInfo);
//   if (access_token || to.path == '/login') {
//     next();
//   } else if (to.path.indexOf('/hematocyte') != -1) {
//     next();
//   } else {
//     router.replace({
//       path: '/login'
//     })
//   }
// })

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')