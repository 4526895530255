/*
 * @Author: your name
 * @Date: 2020-12-01 13:56:48
 * @LastEditTime: 2022-07-28 14:40:01
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: In User Settings Edit
 * @FilePath: \slide-tct\src\vuex\store.js
 */
import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

const state = {
  checkCode: [],
  userInfo: JSON.parse(sessionStorage.getItem("userInfo")) || {},
  printscreenImgNum: 0,  // 截图数量
  rightMenu: {
    status: "none",
    top: "0px",
    left: "0px",
    list: []
  }
}

const mutations = {
  mutationsSetCheckCode(state, n) {
    return (state.checkCode = n)
  },
  setuserInfo(state, v) {
    //将传递的数据先保存到localStorage中
    sessionStorage.setItem('userInfo', JSON.stringify(v));
    // 之后才是修改state中的状态
    state.userInfo = v;
  },
  getPrintscreenImgNum(state, imgNum) {
    state.printscreenImgNum = imgNum;
    sessionStorage.setItem('printscreenImgNum', JSON.stringify(imgNum));
  },
  // 更新右键菜单数据
  updateRightMenuStatus(state, menuObj) {
    state.rightMenu.status = menuObj.status;
    state.rightMenu.top = menuObj.top;
    state.rightMenu.left = menuObj.left;
    state.rightMenu.list = menuObj.list;
  }
}


const actions = {
  actionsetCheckCode({ commit }, n) {
    return commit('mutationsSetCheckCode', n)
  },
}

export default new Vuex.Store({
  state,
  mutations,
  actions
})